import logout from './logout.svg'
import user from './user.svg'
import dashboard from './dashboard.svg'
import users from './users.svg'
import needs from './needs.svg'
import leads from './leads.svg'
import dashboard2 from './dashboard2.svg'
import users2 from './users2.svg'
import needs2 from './needs2.svg'
import leads2 from './leads2.svg'
import redlogo from './redlogo.svg'
import pinned from './pinned.svg'
import hidden from './hidden.svg'

export const Icons = {
    LOGOUT: logout,
    USER: user,
    DASHBOARD: dashboard2,
    USERS_DARK: users2,
    NEEDS_DARK: needs2,
    LEADS_DARK: leads2,
    DASHBOARD_DARK: dashboard,
    USERS: users,
    NEEDS: needs,
    LEADS: leads,
    RED_LOGO: redlogo,
    PINNED: pinned,
    HIDDEN: hidden,
}