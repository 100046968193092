import axios from "axios";

const axiosInstance = axios.create({
    baseURL: 'https://test1.elred.io'
})

const axiosInstanceHeader = axios.create({
    baseURL: 'https://test1.elred.io',
    headers: {
        'Content-Type': 'application/json',
    }
})

axiosInstanceHeader.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)


export { axiosInstance, axiosInstanceHeader };