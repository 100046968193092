import "./App.scss";
import Routing from "./Routing";
import { createContext, useEffect, useState } from "react";

export const LogoutPopUp = createContext();
export const Auth = createContext()


function App() {
  const [logoutPop, setLogoutPop] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(undefined)
  console.log("main : test1.elred.io")

  return (
    <Auth.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      <LogoutPopUp.Provider value={{ logoutPop, setLogoutPop }}>
        <Routing />
      </LogoutPopUp.Provider>
    </Auth.Provider>
  );
}

export default App;
