import "./default-static-popover.scss";

const DefaultStaticPopover = ({ position, headerText, bodyText, isArray }) => {
    const popoverClasses = `popover bs-popover-${position} customPopover${isArray ? ' popoverArrowLeft' : ''}`;

    return (
        <div className={popoverClasses}>
            <div className={`popover-arrow popover-arrow-${position} ${isArray ? 'popoverArrowLeft' : ''}`}></div>
            <div className="popover-header">{headerText}</div>
            <div className="popover-body">
                {
                    isArray ?
                        bodyText?.map((item, id, arr) => (
                            item?.value + (arr.length - 1 == id ? "" : ', ')
                        )) : bodyText
                }
            </div>
        </div>
    );
}

export default DefaultStaticPopover;
