import React from 'react'
import './loginemail.scss'
import { Spinner } from 'react-bootstrap'

const LoginEmail = ({ email, setEmail, requestOtp, loading, wrongEmail, setWrongEmail, noUser, setNoUser }) => {

    const setEmailId = (e) => {
        setWrongEmail(false);
        setNoUser(false);
        setEmail(e.target.value);
    }
    return (
        <div className='login_screen'>
            <div className="title">Login</div>
            <input type="email" placeholder="Email ID" value={email}
                className={wrongEmail ? 'invalid_email_input' : 'email_input'}
                onChange={setEmailId} disabled={loading} /><br />
            {wrongEmail && <span className='error_msg'>Invalid Email Id</span>}
            {noUser && <span className='error_msg'>Permission Denied - not an admin user</span>}
            <div className="otp_txt">OTP will be sent to your registered email ID.</div>
            <button className='get_otp_btn' onClick={loading ? null : () => requestOtp()}>
                {loading ? <Spinner animation="border" variant="light" size="sm" /> : 'Send OTP'}
            </button>
        </div>
    )
}

export default LoginEmail
